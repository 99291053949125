import {Cookie} from "universal-cookie";

export const PEOPLE = 1;
export const SERVICES = 2;
export const FINANCIALS = 4;
export const OPD = 8;
export const IPD = 16;
export const RADIOLOGY = 32;
export const BILLING = 64;
export const INVENTORY = 128;
export const CLIENT = 256;
export const LAB = 512;
export const OPHTHALMOLOGY = 1024;
export const ACCESS_OPD_REPORTS = 2048;
export const BILLING_DISCOUNT = 4096;
export const OPD_BILLING = 8192;
export const IPD_CASE_MGT = 16384;
export const IPD_BILLING = 32768;
export const IPD_DISCHARGE = 65536;
export const DELETE_RECEIPT = 131072;


export enum Roles {
    CLIENT = 272,
    ENTITY_ADMIN = 261887,
    FD_EXEC = 8200,
    INV_MANAGER = 135360,
    SALES_EXEC = 64,
    RADIOLOGIST = 32,
    LAB_MANAGER = 131584,
    OPHTHALMIC_TECHNICIAN = 1024,
    NURSING_STAFF = 16,
    IPD_EXECUTIVE = 49168,
    IPD_MANAGER = 249872,
    OPD_MANAGER = 145416
}

export function getRolePermissions(role) {
    if (role == "client") {
        return Roles.CLIENT
    } else if (role == "entity_admin") {
        return Roles.ENTITY_ADMIN
    } else if (role == "fd_exec") {
        return Roles.FD_EXEC
    } else if (role == "inv_manager") {
        return Roles.INV_MANAGER
    } else if (role == "sales_exec") {
        return Roles.SALES_EXEC
    } else if (role == "radiologist") {
        return Roles.RADIOLOGIST
    } else if (role == "lab_manager") {
        return Roles.LAB_MANAGER
    } else if (role == "ophthalmic_technician") {
        return Roles.OPHTHALMIC_TECHNICIAN
    } else if (role == "nursing_staff") {
        return Roles.NURSING_STAFF
    } else if (role == "ipd_executive") {
        return Roles.IPD_EXECUTIVE
    } else if (role == "ipd_manager") {
        return Roles.IPD_MANAGER
    } else if (role == "opd_manager") {
        return Roles.OPD_MANAGER
    } else {
        return 0
    }
}

export function hasInternalPermissions(cookie: Cookie, permission: number): boolean {
    if (cookie == null) return false;
    if (cookie["role"] == "admin") return true;
    return hasPermissions(cookie, permission)
}

export function hasPermissions(cookie: Cookie, permission: number): boolean {
    if (cookie == null) return false;
    if (cookie["role"] == "admin") return true;
    const permissions = Number(cookie["permissions"])
    return !!(permissions & permission)
}

export function hasPermissionsForManagePage(cookie: Cookie): boolean {
    return hasPermissions(cookie, PEOPLE) || hasPermissions(cookie, SERVICES) || hasPermissions(cookie, FINANCIALS)
}

export function hasPermissionsForBillingInventoryPage(cookie: Cookie): boolean {
    return hasPermissions(cookie, INVENTORY) || hasPermissions(cookie, BILLING)
}

export function hasPharmacyAccessOnly(cookie: Cookie) {
    return (hasInternalPermissions(cookie, BILLING) || hasInternalPermissions(cookie, INVENTORY)) && !(hasInternalPermissions(cookie, OPD) || hasInternalPermissions(cookie, IPD));
}

export function hasRadiologyAccessOnly(cookie: Cookie) {
    return (hasInternalPermissions(cookie, RADIOLOGY)) && !(hasInternalPermissions(cookie, OPD) || hasInternalPermissions(cookie, IPD));
}

export function hasLabAccessOnly(cookie: Cookie) {
    return (hasInternalPermissions(cookie, LAB)) && !(hasInternalPermissions(cookie, OPD) || hasInternalPermissions(cookie, IPD));
}
