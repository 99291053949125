import {BILLING, hasInternalPermissions, INVENTORY, IPD, OPD} from "./permissions";
import Cookies from "universal-cookie";
import {cleanCookies} from "universal-cookie/lib/utils";

export function formatDate(input) {
    var datePart = input.match(/\d+/g),
        year = datePart[0],
        month = datePart[1], day = datePart[2];

    return day + '/' + month + '/' + year;
}

export function formatDateToStandard(input) {
    var datePart = input.match(/\d+/g),
        year = datePart[2],
        month = datePart[1], day = datePart[0];

    return year + '/' + month + '/' + day;
}


export function randomStringLong() {
    const length = 16
    const chars = '0123456789'
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export function formatDateToLocale(input) {
    var year = input[2],
        month = input[1], day = input[0];

    return year + '/' + month + '/' + day;
}

export function validatePhoneNumber(mobile): Boolean {
    try {
        if (mobile.toString().length != 10) {
            return false
        }
        const ph = Number(mobile)
        return ph < 9999999999 && ph > 1000000000;
    } catch (ex) {
        return false
    }
}

export function validateEmail(email): Boolean {
    try {

        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true
        }
        return false
    } catch (ex) {
        return false
    }
}

export function randomString() {
    const length = 6
    const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}

export function buildRadiologyQueueId() {
    var currentTime = new Date();
    var currentOffset = currentTime.getTimezoneOffset();
    var ISTOffset = 330;   // IST offset UTC +5:30
    var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);
    return "radiology" + "_" + ISTTime.toISOString().slice(0, 10) + "_" + 0
}

export function buildInventoryQueueId() {
    var currentTime = new Date();
    var currentOffset = currentTime.getTimezoneOffset();
    var ISTOffset = 330;   // IST offset UTC +5:30
    var ISTTime = new Date(currentTime.getTime() + (ISTOffset + currentOffset) * 60000);
    return "inventory" + "_" + ISTTime.toISOString().slice(0, 10) + "_" + 0
}

export function compareById(a, b) {
    if (a.id < b.id) {
        return -1;
    }
    if (a.id > b.id) {
        return 1;
    }
    return 0;
}

export const doctorCategories = ["General Physician", "Dentist", "Dermatologist", "Gynecologist/Obstetrician",
    "Pediatrician", "ENT Specialist", "Psychiatrist", "Orthopedist", "Homoeopath", "Pulmonologist", "Neurologist", "Veterinarian", "Cardiologist", "Surgeon", "Nephrologist", "Urologist", "ophthalmologist",
    "Radiation Oncologist", "Medical Oncologist", "General Surgeon", "Surgical oncologist", "Gastroenterologist", "Rheumatologist", "Procto Surgeon", "Laparoscopic Surgeon", "Cosmetologist", "Physiotherapist"];

export const vitals = [{id: "weight", name: "Wt.", unit: "Kg"},
    {id: "temperature", name: "Temp.", unit: "Fahrenheit"},
    {id: "bp", name: "B.P.", unit: "mmHg"},
    {id: "pulseRate", name: "P.R.", unit: "bpm"},
    {id: "spo2", name: "SPO2", unit: "%"},
    {id: "rbs", name: "Rbs", unit: "mg/dl"},
    {id: "fbs", name: "Fbs", unit: "mg/dl"},
    {id: "ppbs", name: "Ppbs", unit: "mg/dl"},
    {id: "chief_complaints", name: "Chief Complaints"},
    {id: "hpi", name: "History of Present Illness"},
    {id: "drug_allergy", name: "Drug Allergy"},
    {id: "treatment_history", name: "Treatment History"},
    {id: "birth_history", name: "Birth History"},
    {id: "immunization_status", name: "Immunization Status"},
    {id: "personal_history", name: "Personal History"},
    {id: "mental_status", name: "Mental Status", options: "Alert,Oriented TPP,Mood/Affect Nml"}];


export const moveFocus = (steps) => {
    var inputs = document.getElementsByTagName('input');
    for (var i = 0; i < inputs.length; i++) {
        if (document.activeElement.id == inputs[i].id && i + 1 < inputs.length - 1) {
            inputs[i].blur();
            inputs[i + steps].focus();
            break;
        }
    }
}

export function padNumber(number) {
    return number.toString().padStart(5, '0')
}

export function handleLogout() {
    cleanCookies();
    window.location.href = window.location.origin + "/login"
}

export function capitalise(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}